import { Component } from 'react';
import Zmage from 'react-zmage';
import Fade from 'react-reveal';


class Portfolio extends Component {
  render() {
    if (!this.props.data) return null;

    const projects = this.props.data.projects.map(function (projects, key) {
      let projectImage = 'images/portfolio/' + projects.image;

      return (
        <div key={key} className="columns portfolio-item">
          <div className="item-wrap">
            <Zmage alt={projects.title} src={projectImage} />
            <div rel="noreferrer" style={{ textAlign: 'center' }}>
              {projects.title}
              <a target="_blank" href={projects.url} rel="noreferrer">
                Check it out!
              </a>
            </div>
          </div>
        </div>
      );
    });

    return (
      <section id="portfolio">
        <Fade left duration={1000} distance="20px">
          <div className="row">
            <div className="twelve columns collapsed">
              <h1>Check Out Some of My Works.</h1>

              <div
                id="portfolio-wrapper"
                className="bgrid-quarters s-bgrid-thirds cf"
              >
                {projects}
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default Portfolio;
