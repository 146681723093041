import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

ReactDOM.render(<App />, document.getElementById("root"))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// <!-- Matomo -->
{
    /* <script> */
}
var _paq = (window._paq = window._paq || [])
/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
_paq.push(["setDocumentTitle", document.domain + "/" + document.title])
_paq.push(["setCookieDomain", "*.F2had.me"])
_paq.push(["setDomains", ["*.F2had.me"]])
_paq.push(["trackPageView"])
_paq.push(["enableLinkTracking"])
;(function () {
    var u = "//f2had.me/piwik/"
    _paq.push(["setTrackerUrl", u + "matomo.php"])
    _paq.push(["setSiteId", "2"])
    var d = document,
        g = d.createElement("script"),
        s = d.getElementsByTagName("script")[0]
    g.async = true
    g.src = u + "matomo.js"
    s.parentNode.insertBefore(g, s)
})()
// </script>
// <!-- End Matomo Code -->
